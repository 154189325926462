import React from "react";

import "../../style.scss";
import ItemWork from "./ItemWork";
import ProgresBar from "./ProgresBar";
const Work = (props) => {
  return (
    <section id="How does it work" className="work">
      <div className="work__wraper">
        <h2 className="work__title title">
          How does <span>it work</span>
        </h2>
        <p className="work__sup-title text">
          Guar Finance operates on a straightforward yet innovative mechanism
          within the DeFi ecosystem.
        </p>
        <ItemWork />
        <ProgresBar />
      </div>
    </section>
  );
};

export default Work;
