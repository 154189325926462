import React from "react";

import "./Links.scss";
import H2Title from "../H2Title";
import Block from "../block/Block";

import discord from "../../../img/discord.svg";
import twitter from "../../../img/twitter.svg";
import telegram from "../../../img/telegram.svg";

const Links = (props) => {
const clickDiscord =()=>{
 
  window.BMDataLayer = window.BMDataLayer || [];
  window.BMDataLayer.push({
    conversionID: "66586d8e02d8f83749606513",
    eventId: "Tap Discord",
    event: "conversion",
    eventValue: "{ConversionValueUSD}",
  });
}
const clickTelegram=()=>{window.BMDataLayer = window.BMDataLayer || [];
window.BMDataLayer.push({
  conversionID: "66586db229bc78f72e818e0d",
  eventId: "Tap Telegram",
  event: "conversion",
  eventValue: "{ConversionValueUSD}",
});}
const clickTwitter = () => {window.BMDataLayer = window.BMDataLayer || [];
window.BMDataLayer.push({
  conversionID: "66586d7429bc78f72e818da0",
  eventId: "TapX(Twitter)",
  event: "conversion",
  eventValue: "{ConversionValueUSD}",
});};





  return (
    <section id=" Links" className="links">
      <H2Title span={"6."} title={"Links"} />
      <Block
        start={"Become a part of the Guar community"}
        text={
          " on all of our social media channels to lead the way in DeFi innovation! By following us on social media, you can keep up with everything Guar, including the newest product releases, local happenings, instructional materials, and insider information. "
        }
      />
      <Block
        text={
          "Talk to other DeFi aficionados, offer your ideas, and work with us to influence the financial landscape. Come grow with us and be a part of the community transforming the DeFi business."
        }
      />
      <p className="links__title-mini title-mini">
        Stay <span>connected,</span> stay <span>informed,</span> and{" "}
        <span>let's create success</span> together:
      </p>
      <div className="links__social">
        <a onClick={clickDiscord}
          href="https://discord.gg/dWfHAfEBHr "
          className="links__discord "
          target="_blank"
          rel="noreferrer"
        >
          <img src={discord} alt="discord" />
        </a>
        <a onClick={clickTelegram}
          href="https://t.me/guarfinance"
          target="_blank"
          className="links__telegram "
          rel="noreferrer"
        >
          <img src={telegram} alt="telgram" />
        </a>
        <a onClick={clickTwitter}
          href="https://twitter.com/GuarFinance "
          className="links__twitter "
          target="_blank"
          rel="noreferrer"
        >
          <img src={twitter} alt="twitter" />
        </a>
      </div>
    </section>
  );
};

export default Links;
