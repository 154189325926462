import React from "react";

import "./Finanse.scss";
import H2Title from "../H2Title";
import Block from "../block/Block";
import img01 from "../../../img/overview/01.svg";
import img05 from "../../../img/overview/05.svg";
import line from "../../../img/bgLine.svg";
const Finanse = (props) => {
  return (
    <section id=" What is Guar Finance?" className="finanse">
      <H2Title span={"3."} title={"What is Guar Finance?"} />
      <Block
        start={"Guar Finance"}
        text={
          " is a ground-breaking DeFi platform made to tackle the problems that come with the decentralized finance industry, namely with regard to risk management of digital assets, accessibility, and liquidity pools. Guar Finance seeks to improve the DeFi experience by bringing cutting-edge technologies like Protected Liquidity Provision (PLP) that make it easier, safer, and more profitable for a larger group of people."
        }
      />
      <Block
        start={"Guar Finance"}
        text={
          " addresses the danger and complexity involved in using DeFi liquidity pools. It might be intimidating for less seasoned users to interact with the ecosystem on traditional DeFi platforms because they frequently expose users to erratic revenues and perhaps transient losses. Guar Finance offers a more user-friendly and risk-mitigated pathway to DeFi investment by streamlining this process."
        }
      />
      {/* <h5 className="finanse__title title-medium">
        <span>Key</span> Features
      </h5>
     
      <Block
        cssClass={"block-bg"}
        span={"Staking "}
        text={
          "Guar Finance presents various staking modalities, including solo home staking, Staking as a Service (SaaS), and pooled staking, catering to different user preferences and capital availability."
        }
        title={"Options"}
      /> */}
      <h5 className="finanse__title title-medium">
        <span>Key</span> Advantages
      </h5>
      <Block
        cssClass={"block-bg"}
        span={" "}
        title={"Simplified"}
        end={"Participation"}
        text={
          "Guar Finance reduces the barrier to entry for users looking to participate in DeFi, offering a more intuitive and straightforward approach to investment."
        }
      />
      <Block
        cssClass={"block-bg"}
        span={" "}
        title={"Risk"}
        end={"Mitigation"}
        text={
          "Through PLP, the platform protects users from the common risks of impermanent loss and market volatility, securing their investments against unforeseen downturns."
        }
      />
      {/* <Block
        cssClass={"block-bg"}
        span={" "}
        end={"Earning Strategies"}
        title={"Diverse"}
        text={
          "The platform supports multiple earning strategies, including staking and market making, providing users with various avenues to grow their digital assets."
        }
      /> */}
      <Block
        cssClass={"block-bg"}
        span={"Protected"}
        title={" Liquidity Provision (PLP)"}
        text={
          "Allows users to deposit assets in a way that minimizes their exposure to loss, while still enabling them to earn yield from DeFi liquidity pools"
        }
      />
      <Block
        cssClass={"block-bg"}
        span={"Accessibility"}
        title={""}
        text={
          "With a focus on user experience, Guar Finance makes DeFi more accessible, offering a platform that is easy to navigate and engage with, even for those new to the crypto space."
        }
      />
      <Block
        cssClass={"block-bg"}
        span={"Stability"}
        end={""}
        title={"and Security"}
        text={
          "The platform emphasizes security and the stability of returns, aiming to build trust within the DeFi community through protected investment mechanisms."
        }
      />
      <Block
        textWhite
        start={"Guar Finance"}
        text={
          " brings a fresh perspective to DeFi, improving its security and widening user base. Guar Finance distinguishes itself as a platform that not only streamlines the investing process but also provides strong protection mechanisms, hence improving the entire user experience and possible profits in the DeFi ecosystem. This is achieved by tackling the primary difficulties facing the present DeFi market."
        }
      />
      <div className="finanse__bg-line ">
        <img src={line} alt="line" />
      </div>
      <h5 className="finanse__title title-medium">
        <span>Simplifying</span> DeFi Investments
      </h5>
      <Block
        text={
          "By lowering the barrier to investing and increasing accessibility for all consumers, regardless of skill level, Guar Finance is revolutionizing the DeFi market. This simplicity is made possible by a number of cutting-edge features and procedures intended to reduce entry barriers and lessen the risks usually connected with DeFi. Guar Finance is making DeFi investing easier in the following ways:"
        }
      />
      <Block
        cssClass={"block-bg"}
        span={"Intuitive "}
        title={"Platform Design"}
        text={
          "Guar Finance prioritizes an intuitive user interface that simplifies navigation and transaction processes, making it easier for users to understand and engage with DeFi products and services."
        }
      />
      <Block
        cssClass={"block-bg"}
        span={"Simplified "}
        title={"Investment Steps"}
        text={
          "By streamlining the investment process, Guar Finance reduces the complexity involved in participating in liquidity pools and market making. Users can invest with just a few clicks, without needing to understand the underlying technical mechanisms."
        }
      />
      <h5 className="finanse__title title-medium">
        <span> Protected</span> Liquidity Provision (PLP)
      </h5>
      <div className="progres">
        <ul className="finanse__list ">
          <li className="finanse__item img">
            <Block
              cssClass={"block-bg "}
              url={img01}
              span={"Accessible"}
              title={" Liquidity Pools"}
              text={
                "Guar Finance's PLP system allows users to deposit their assets in a protected manner, significantly reducing the learning curve and risk associated with direct investment in DEX liquidity pools."
              }
            />
          </li>
          <li className="finanse__item img">
            <Block
              cssClass={"block-bg  "}
              url={img05}
              span={""}
              end={"Risk Mitigation"}
              title={"Fixed Returns and "}
              text={
                "The PLP feature offers fixed percentage yields, making it clearer for investors what returns to expect. It also provides a safety net against losses, ensuring the initial investment is protected against market volatility."
              }
            />
          </li>
        </ul>
      </div>
      <div className="finanse__bg-line">
        <img src={line} alt="line" />
      </div>
      <h5 className="finanse__title title-medium">
        Diverse <span> and</span> Flexible Staking Options
      </h5>
      
        <ul className="finanse__list ">
          {/* <li className="finanse__item img">
            <Block
              cssClass={"block-bg "}
              span={"Multiple "}
              title={"Staking Modalities"}
              text={
                "Guar Finance caters to various user preferences and capital sizes through solo home staking, Staking as a Service (SaaS), and pooled staking options. This flexibility allows users to choose the staking model that best fits their needs and risk tolerance."
              }
            />
          </li> */}
          <li className="finanse__item img">
            <Block
              cssClass={"block-bg "}
              span={"Low "}
              title={"Entry Barriers"}
              text={
                " The platform provides options for users with different amounts of capital, including those with lower funds who wish to participate in pooled staking, ensuring DeFi is accessible to a broader audience."
              }
            />
          </li>
          {/* <li className="finanse__item img">
            <Block
              cssClass={"block-bg "}
              span={"Responsive "}
              title={"Customer Support"}
              text={
                "The platform provides robust support services to assist users with any questions or issues, enhancing confidence and trust in navigating the DeFi space."
              }
            />
          </li> */}
        </ul>
      
      <Block
        textWhite
        text={
          "By making it easier and safer for people to invest in and take advantage of decentralized finance, Guar Finance is raising the bar for DeFi. Guar Finance effectively lowers entrance barriers, opening up DeFi investments to a far wider audience with its user-friendly interface, safeguarded investment processes, flexible staking possibilities, and extensive training resources. This strategy helps the DeFi ecosystem as a whole expand and remain stable while also improving the user experience."
        }
      />
      <div className="finanse__bg-line">
        <img src={line} alt="line" />
      </div>
      <h5 className="finanse__title title-medium">
        <span> Protected</span> Liquidity Provision (PLP)
      </h5>
      <Block
        text={
          "Guar Finance's flagship product, Protected Liquidity Provision (PLP), is intended to transform the way customers engage with DeFi liquidity pools by providing a more secure and approachable investment choice. Many of the issues that are typically connected to DeFi investments—such as complexity, loss risk, and accessibility – are addressed by this novel strategy."
        }
      />
      <h5 className="finanse__title title-medium">
        How<span> PLP </span> Works?
      </h5>
      <Block
        text={
          "Users can deposit assets into PLP, specifically USDT, and the system will distribute them across several liquidity pools on the ChromaDEX platform. By offering consumers a set income on their investments, the method reduces the risks often connected to making direct investments into DEX liquidity pools."
        }
      />
      <h5 className="finanse__title title-medium">
        Key Components of <span>PLP </span>
      </h5>
      <div className="finanse__components components-finanse">
        <div className="comonents-finanse__img">
          {/* <img src={logo} alt="logo3D" /> */}
        </div>
        <div className="components-finanse__text-box_1 text-box-finanse">
          <h6 className="text-box-finanse__title">
            <span> Simplified</span> Investment Process
          </h6>
          <p className="text-box-finanse__sub-title">
            By depositing USDT into the PLP system, users effectively
            participate in multiple liquidity pools without the need to
            understand the intricate workings of each pool or the DeFi ecosystem
            at large.
          </p>
        </div>
        <div className="components-finanse__text-box_2  text-box-finanse">
          <h6 className="text-box-finanse__title">
            <span>Risk </span> Mitigation
          </h6>
          <p className="text-box-finanse__sub-title">
            Guar Finance employs a hedgers pool to protect against losses. This
            pool acts as a safety net, ensuring that the initial investment of
            users is safeguarded against the volatility of the crypto market.
          </p>
        </div>
        <div className="components-finanse__text-box_3 text-box-finanse">
          <h6 className="text-box-finanse__title">
            <span>Commissions</span>
          </h6>
          <p className="text-box-finanse__sub-title">
            To support the operation and risk management strategies of the PLP
            system, small deposit and withdrawal commissions are applied,
            typically around 0.2%.
          </p>
        </div>
        <div className="components-finanse__text-box_4 text-box-finanse">
          <h6 className="text-box-finanse__title">
            Unit Price <span>Model</span>
          </h6>
          <p className="text-box-finanse__sub-title">
            Investments are represented as units within the system, with each
            unit initially priced at 1 USDT. The value of these units increases
            over time based on the predefined interest rate, ensuring that users
            can benefit from the growth of their investment in a transparent and
            predictable manner.
          </p>
        </div>
        <div className="components-finanse__text-box_5 text-box-finanse">
          <h6 className="text-box-finanse__title">
            <span>Fixed</span> Yield
          </h6>
          <p className="text-box-finanse__sub-title">
            The yield for PLP investors is predetermined (e.g., 8% per annum).
            This fixed yield model provides a predictable return on investment,
            contrasting with the variable returns seen in typical liquidity pool
            investments.
          </p>
        </div>
      </div>
      <h5 className="finanse__title title-medium">
        Advantages of <span>PLP</span>
      </h5>
      <Block
        cssClass={"block-bg"}
        span={" "}
        title={"Ease "}
        end={"of Use"}
        text={
          "PLP demystifies the process of investing in DeFi for the average user, making it more accessible to a wider audience."
        }
      />
      <Block
        cssClass={"block-bg"}
        span={"Protection "}
        title={"  from Losses"}
        text={
          "The unique structure of PLP, particularly the hedgers pool, provides a layer of protection against the impermanent loss and market volatility that can erode investment value in traditional liquidity pools."
        }
      />
      <Block
        cssClass={"block-bg"}
        span={""}
        title={"Predictable "}
        end={"Returns"}
        text={
          "The fixed yield model offers investors a clear understanding of their potential earnings, facilitating better financial planning and investment strategy."
        }
      />
      <Block
        cssClass={"block-bg"}
        span={"Flexibility"}
        text={
          "Users have the flexibility to withdraw their investments at any point, selling their units back to the system based on the current unit price, which is designed to never fall below the initial investment value."
        }
      />
      <Block
        textWhite
        text={
          "A notable breakthrough in the DeFi field is Guar Finance's Protected Liquidity Provision (PLP) system, which offers a balanced approach to liquidity pool investment that puts user usability, risk mitigation, and predictable returns first. PLP presents a strong choice for both experienced and inexperienced investors wishing to interact with the DeFi ecosystem in a more secure and transparent way because it streamlines the investing procedure and shields users from typical hazards."
        }
      />
      <div className="finanse__bg-line">
        <img src={line} alt="line" />
      </div>
      {/* <h5 className="finanse__title title-medium">
        Staking <span> and</span> Farming Features in <span> Guar Finance</span>
      </h5>
      <Block
        text={
          "Guar Finance enhances its platform's appeal by incorporating staking and farming functionalities, key components in the DeFi ecosystem that offer users additional avenues for earning yield on their digital assets."
        }
      />
      <Block
        cssClass={"block-bg"}
        span={""}
        end={"Guar Finance"}
        title={"Staking at "}
        text={
          "Users of Guar Finance can lock up their tokens in exchange for rewards by using staking. This feature pays users with a portion of transaction fees or newly generated tokens in addition to encouraging token ownership, which helps maintain the stability of the network. With choices for single-home staking, Staking as a Service (SaaS), and pooled staking to suit different investment levels and technical skill levels, Guar Finance seeks to make staking simple and approachable."
        }
      />
      <Block
        cssClass={"block-bg"}
        span={"Farming "}
        title={" Opportunities"}
        text={
          "By adding liquidity to the platform's several pools, users can receive rewards through farming, also known as yield farming. Guar Finance takes common worries like temporary loss and complicated participation requirements into account when designing its farming chances to make them more secure and user-friendly. Users that engage in farming have the potential to receive bigger rewards than those who engage in standard staking because they are contributing to the liquidity and general health of the platform."
        } */}
      {/* /> */}
      {/* <h5 className="finanse__title title-medium">
        <span>Key Benefits</span> of Staking <span> and Farming</span>
      </h5>
      <Block
        span={"1."}
        title={"Diversified Earning Strategies:"}
        text={
          "Staking and farming provide users with multiple strategies to earn returns on their investments, from relatively stable staking rewards to potentially higher, albeit more variable, farming yields."
        }
      />
      <Block
        span={"2."}
        title={"Security and Stability:"}
        text={
          "Through staking, particularly via solo home  staking, Guar Finance leverages the collective security contributions of its users, enhancing the network's resilience and stability."
        }
      />
      <Block
        span={"  3."}
        title={"Flexibility and Accessibility:"}
        text={
          "Offering various staking and farming  options ensures that both novice and experienced users can find suitable ways to participate and earn rewards, making DeFi more accessible to a  broader audience."
        }
      /> */}
      <h5 className="finanse__title title-medium">
        Fee Structure in <span>Guar Finance</span>
      </h5>
      <Block
        cssClass={"block-bg"}
        span={"Protected "}
        title={"Liquidity Provision (PLP)"}
        text={
          "Users engaging with the PLP system are charged a commission of approximately 0.2% when they deposit or withdraw funds. This fee is integral to the system's operation, helping to cover the costs associated with providing liquidity and ensuring the platform's sustainability."
        }
      />
      <Block
        cssClass={"block-bg"}
        span={"Gas "}
        title={"Fees"}
        text={
          "Users have to pay a gas fee for acts like opening or closing a deposit inside the larger Guar Finance ecosystem. Guar Finance runs on the EVM-agnostic + Rell (Chromia), where this charge covers the cost of the computing resources required to complete transactions."
        }
      />
    </section>
  );
};

export default Finanse;
