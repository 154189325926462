import React from 'react'


const Title = (props)=>{
  return (
    <h1 className="main__title">
      <span>Guar</span> Finance
    </h1>
  );
}

export default Title