import React from "react";

import "./Introduction.scss";
import H2Title from "../H2Title";
import Block from "../block/Block";
const Introduction = (props) => {
  return (
    <section id="Introduction" className="introduction">
      <H2Title span="1." title=" Introduction" />
      <Block
        cssClass={"introduction__text"}
        text={
          "The blockchain industry's collaborative effort is focused on finding novel solutions that provide security and profitability in the quickly developing field of Decentralized Finance (DeFi). Guar Finance stands out as a leader in innovation, developing Protected Liquidity Provision (PLP) solutions intended to greatly reduce associated risks and increase accessibility to DeFi investments."
        }
      />
      <div className="block-bg-box">
        <Block
          cssClass={"block-bg"}
          span={"Simplifying"}
          title={" DeFi Investments"}
          text={
            "Guar Finance is committed to removing the obstacles of high risk and complexity that are typically associated with market making and liquidity provisioning in decentralized exchanges (DEXs) and making DeFi available to a wider audience. Guar Finance provides easy-to-use, safe, and effective procedures that enable people to confidently engage in the DeFi economy."
          }
        />

        <Block
          cssClass={"block-bg"}
          span={"Protected "}
          title={"Liquidity Provision (PLP)"}
          text={
            "The PLP system is designed to give people an easy-to-use, safe way to participate in liquidity provision. Investors can benefit from fixed percentage rates on their deposits using this system, which is supported by a diversified investment strategy across several liquidity pools to insure against losses."
          }
        />
      </div>

      <Block
        textWhite
        guar="Guar Finance."
        text={
          "The goal of this document is to give a thorough understanding of the technology architecture, guiding principles, and economic models that Guar Finance uses to support its PLP system. We extend an invitation to investors, fans, and stakeholders to pioneer a safer, more accessible, and profitable DeFi landscape by outlining the operational procedures, advantages, and strategic vision of  "
        }
      />
    </section>
  );
};

export default Introduction;
