import React from "react";

const MenuList = ({ customStyle ,onActive,onClick,footer}) => {
  const menuItems = ["How does it work", "Our Features",'Roadmap', "Whitepaper", "FAQ"];
  const handleClick = (e,item) => {
    if(!footer){
      onActive()

    }
    e.preventDefault();
    if (item === "Whitepaper"){
      window.BMDataLayer = window.BMDataLayer || [];
      window.BMDataLayer.push({
        conversionID: "66586ca129bc78f72e8180f4",
        eventId: "ViewWhitePaper",
        event: "conversion",
        eventValue: "{ConversionValueUSD}",
      }); 
      onClick(false)

    }

 
    const targetElement = document.getElementById(item);

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  return (
    <ul style={customStyle} className="menu__list">
      {menuItems.map((item) => {
        return (
          <li key={item} className={footer ? "menu__item footer__item" : "menu__item"}>
            <a
              onClick={(e) => handleClick(e, item)}
              href={`#${item}`}
              className="menu__link"
            >
              {item}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default MenuList;
