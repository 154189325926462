import './App.scss';
import './variable/variable.scss'
import Fag from './components/fag/Fag';
import Features from './components/features/Features';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Main from './components/main/Main';
import Work from './components/work/Work';
import Whitepeper from './components/whitepaper/Whitepeper';
import { useState } from 'react';
import Roadmap from './components/roadmap/Roadmap';

function App() {
  const [main,setMain] = useState(true)
  const handleMain = (rez)=>{

    setMain(main=>rez)
  }
  return (
    <div className="App">
      {main ? (
        <>
          <Header app onClick={handleMain} />
          <Main />
          <Work />
          <Features />
          <Roadmap/>
          <Fag />
          <Footer app onClick={handleMain} />

        </>
      ) : (
        <Whitepeper onClick={handleMain} />
      )}
    </div>
  );
}

export default App;
