import React, { useState } from "react";

import ItemText from "./ItemText";
const fagItemInfo = [
  {
    title: "What is Guar Finance?",
    text: "Guar Finance is a decentralized finance (DeFi) platform implemented on the EVM-agnostic + Rell (Chromia). The Guaranteed Liquidity Provision Yield (GLPY) method is what Project does best when it comes to making goods that make the process of providing liquidity and making money easier for users.",
    id: 1,
  },
  {
    title: "How does the GLPY system work?",
    text: "Users can put USDT into the GLPY system and then take it out later. The method aims to give a set annual rate (for example, 8%) based on how long the bond stays in the system. This return comes from the fees that liquidity providers in decentralized markets (DEXes) get paid.",
    id: 2,
  },
  {
    title:
      "What makes investing in GLPY different from direct DEX liquidity provision?",
    text: "Direct investment in DEX liquidity pools can be complex and risky due to price fluctuations and impermanent loss. GLPY simplifies this process for users, offering a more user-friendly interface and risk mitigation strategies, such as diversification and a hedger pool to protect against losses.",
    id: 3,
  },
  {
    title: "What is the role of a hedger in Guar Finance?",
    text: "Hedgers in Guar Finance are responsible for managing liquidity pool (LP) positions and selecting the best pools to maximize yield. They play a key role in diversifying investments and mitigating risks for the users.",
    id: 4,
  },
  {
    title: "How is the yield generated in the GLPY system?",
    text: "The yield in the GLPY system is generated through commissions that are earned from users swapping tokens on various DEXes. The system pools user funds into different liquidity pools to earn these commissions.",
    id: 5,
  },
  {
    title: "What are the fees associated with Guar Finance's services?",
    text: "Guar Finance charges deposit and withdrawal commissions, which are approximately 0.2%. These fees are applied when users deposit or withdraw their funds from the system.",
    id: 6,
  },
  {
    title: "How does the unit pricing system work in GLPY?",
    text: "Users buy units in the GLPY system at an initial price of 1.0 USDT per unit. The price of these units is designed to increase at a predefined interest rate. For instance, with an 8% annual interest rate, the unit price would grow to 1.08 USDT after one year.",
    id: 7,
  },
  {
    title: "Can anyone become a hedger in Guar Finance?",
    text: "Yes, anyone can become a hedger in Guar Finance, but it requires knowledge and experience in managing LP positions and hedging strategies, as it involves complex decision-making to optimize returns and manage risks.",
    id: 8,
  },
  {
    title: "What happens if the value of LP tokens decreases?",
    text: "If the value of LP tokens in a pool decreases below a certain threshold, the system converts these tokens back to USDT. This, combined with the funds from the hedging pool, is then available for withdrawal, thus minimizing potential losses for users.",
    id: 9,
  },
  {
    title:
      "Is investing in Guar Finance's GLPY system suitable for all types of investors?",
    text: "While Guar Finance aims to simplify and reduce the risks of DeFi investments, potential investors should still conduct their research and consider their risk tolerance. The platform is suited for those who seek exposure to DeFi yields but prefer a more structured and less hands-on approach than direct DEX liquidity provision.",
    id: 10,
  },
];

const FagItem = () => {
  const [activeButton, setActiveButton] = useState({});

  const toggleActiveButton = (id) => {
    setActiveButton((prevActiveButton) =>
      prevActiveButton === id ? null : id
    );
  };

  return (
    <ul className="faq__list">
      {fagItemInfo.map((item, ind) => {
        return (
          <li onClick={() => toggleActiveButton(item.id)}
            key={item.id}
            className={`faq__item item-faq ${
              activeButton === item.id && "item-faq item-faq__active"
            } `}
          >
            <div className="item-faq__top">
              <h6 className="item-faq__title">
                <span>
                  {item.id !== 10 ? 0 : ""}
                  {item.id}
                </span>
                {item.title}
              </h6>
              <button
                
                className={
                  activeButton === item.id
                    ? "item-faq__button item-faq__button-active"
                    : "item-faq__button"
                }
              >
                <span></span>
              </button>
            </div>
            <ItemText
              visable={activeButton === item?.id}
              item={activeButton === item.id && item.text}
            ></ItemText>
          </li>
        );
      })}
    </ul>
  );
};

export default FagItem;
