import React from "react";

import "./NavList.scss";
import Title from "../../main/Title";
import "../../../style.scss";
const navItem = [
  " Introduction ",
  " DeFi Market Overview ",
  " What is Guar Finance?",
  " Roadmap",
  " Conclusion",
  " Links",
];
const NavList = (props) => {
   const handleClick = (e, item) => {
     
     e.preventDefault();
     

     const targetElement = document.getElementById(item);

     if (targetElement) {
       targetElement.scrollIntoView({
         behavior: "smooth",
       });
     }
   };
  return (
    <section className="nav-list">
      <Title />
      <p className="nav-list__sub-title sub-title">Whitepaper</p>
      <ul className="nav-list__list">
        {navItem.map((item,ind)=>{
          return (
            <li key={item} className="nav-list__item">
              <a onClick={(e) => handleClick(e, item)} href={`#${item}`}>
                <span>{ind + 1}.</span>
                {item}
              </a>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default NavList;
