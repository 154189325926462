import React from "react";

import "./Conclusion.scss";
import H2Title from "../H2Title";
import Block from "../block/Block";

const Conclusion = (props) => {
  return (
    <section id=" Conclusion" className="conclusion">
      <H2Title span={"5."} title={"Conclusion"} />
      <Block
        text={
          "Guar Finance is a game-changer in the decentralized finance (DeFi) space, taking on major problems that have impeded user confidence and wider adoption. Guar Finance offers a range of unique services, such as Protected Liquidity Provision (PLP), which serve to both simplify participation in DeFi and greatly reduce the related risks."
        }
      />
      <Block
        text={
          "Guar Finance stands out as a guiding light for both experienced and inexperienced DeFi participants due to its emphasis on user accessibility, risk management, and the diversification of earning possibilities. The platform's dedication to growing throughout numerous blockchain networks and decentralized exchanges (DEXs) supports its goal of creating a more prosperous, safe, and inclusive DeFi ecosystem."
        }
      />
      <Block
        text={
          "The 2024 vision outlines a precise course for development and growth while emphasizing Guar Finance's commitment to innovation, civic involvement, and business alliances. The platform will change the way we think about and interact with DeFi as it develops, opening up new opportunities for yield creation, liquidity provision, and market making. "
        }
      />
      <Block
        text={
          "The objectives of Guar Finance are outlined in this whitepaper. The platform invites users, investors, and enthusiasts to participate in defining a new era of finance that is genuinely decentralized, inclusive, and empowering as it develops and adapts to the always shifting DeFi landscape."
        }
      />
    </section>
  );
};

export default Conclusion;
