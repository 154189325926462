import React from "react";
import "../../style.scss";
import FagItem from "./FagItem";

const Fag = (props) => {
  return (
    <section id="FAQ" className="faq">
      <div className="faq__wraper">
        <h2 className="faq__title title">
          <span>FAQ</span>
        </h2>
        <FagItem />
      </div>
    </section>
  );
};

export default Fag;
