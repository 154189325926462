import { forwardRef, useImperativeHandle, useRef } from "react";
import { createPortal } from "react-dom";
import './Modal.scss'
const Modal = forwardRef(function Modal({ children,id }, ref) {
  const dialog = useRef();

  useImperativeHandle(ref, () => {
    return {
      open: () => {
        dialog.current.showModal();
        document.body.style.overflow = "hidden";
      },
      close: () => {
        dialog.current.close();
        document.body.style.overflow = "";
      },
    };
  });

  const handleClose = (e) => {
   if (e.target === dialog.current) {
     ref.current.close();
   }
  };
  return createPortal(
    <dialog id={id} className="modal  " ref={dialog} onClick={handleClose} >
      {children}
    </dialog>,
    document.getElementById("modal")
  );
});

export default Modal;
