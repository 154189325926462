import React from 'react'
import { motion, AnimatePresence } from "framer-motion";


const ItemText = ({children,visable,item})=>{
   
  return (
    <AnimatePresence>
      {visable && (
        <motion.div
          initial={{ height: 0, opasiti: 0 }}
          animate={{ height: "auto", opasiti: 1 }}
          exit={{ height: 0 }}
          style={{ overflow: "hidden" }}
          transition={{ duration: 1 }}
        >
          <p className="item-faq__text">
            {item}
          </p>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default ItemText