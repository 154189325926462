import React from 'react'
import '../../style.scss'
import discord from "../../img/discord.svg";
import twitter from "../../img/twitter.svg";
import telegram from "../../img/telegram.svg";
const Social = ()=>{
  const clickDiscord = () => {
    
    window.BMDataLayer = window.BMDataLayer || [];
    window.BMDataLayer.push({
      conversionID: "66586d8e02d8f83749606513",
      eventId: "Tap Discord",
      event: "conversion",
      eventValue: "{ConversionValueUSD}",
    });
    
  };
  const clickTelegram = () => {
    window.BMDataLayer = window.BMDataLayer || [];
    window.BMDataLayer.push({
      conversionID: "66586db229bc78f72e818e0d",
      eventId: "Tap Telegram",
      event: "conversion",
      eventValue: "{ConversionValueUSD}",
    });
  };
  const clickTwitter = () => {
    window.BMDataLayer = window.BMDataLayer || [];
    window.BMDataLayer.push({
      conversionID: "66586d7429bc78f72e818da0",
      eventId: "TapX(Twitter)",
      event: "conversion",
      eventValue: "{ConversionValueUSD}",
    });
  };

  return (
    <div className={`body-footer__social social-footer  links-social`}>
      <a onClick={clickDiscord}
        href="https://discord.gg/dWfHAfEBHr "
        className="social-footer__discord discord "
        target="_blank"
        rel="noreferrer"
      >
        <img src={discord} alt="discord" />
      </a>
      <a onClick={clickTelegram}
        href="https://t.me/guarfinance"
        target="_blank"
        className="social-footer__telegram telegram "
        rel="noreferrer"
      >
        <img src={telegram} alt="telgram" />
      </a>
      <a onClick={clickTwitter}
        href="https://twitter.com/GuarFinance "
        
        className="social-footer__twitter twitter "
        target="_blank"
        rel="noreferrer"
      >
        <img src={twitter} alt="twitter" />
      </a>
    </div>
  );
}

export default Social