import React from "react";

import "./Overview.scss";
import H2Title from "../H2Title";
import Block from "../block/Block";
import img01 from "../../../img/overview/01.svg";
import img02 from "../../../img/overview/02.svg";
import img03 from "../../../img/overview/03.svg";
import img04 from "../../../img/overview/04.svg";
import img05 from "../../../img/overview/05.svg";
import img06 from "../../../img/overview/06.svg";
import img07 from "../../../img/overview/07.svg";

const Overview = (props) => {
  return (
    <section id=" DeFi Market Overview " className="overview">
      <H2Title span={"2. "} title={" DeFi Market Overview"} />
      <Block
        text={
          "The market for decentralized finance (DeFi) has experienced rapid expansion, bringing cutting-edge blockchain-based financial services and products to the market. Notwithstanding its progress, a number of issues still exist that affect security, user experience, and overall market efficiency:"
        }
      />
      <div className="progres">
        <ul className="overview__list">
          <li className="overview__item img">
            <Block
              cssClass={"block-bg"}
              url={img01}
              text={
                "Liquidity pools are essential for facilitating decentralized trading, lending, and borrowing. However, their complex nature and the requirement for users to understand various mechanisms (like Automated Market Makers, or AMMs) deter widespread adoption. This complexity can lead to a steep learning curve for new entrants."
              }
              span={""}
              title={`Complexity of `}
              end={"Liquidity Pools"}
            />
          </li>
          <li className="overview__item img">
            <Block
              cssClass={"block-bg"}
              url={img02}
              text={
                "DeFi platforms frequently include user interfaces that are difficult for the general public to understand, especially for individuals without a strong experience in technology or finance. Only a more tech-savvy audience can potentially use this due to its lack of accessibility."
              }
              span={"Accessibility"}
              title={" and Usability"}
            />
          </li>
          <li className="overview__item img">
            <Block
              cssClass={"block-bg"}
              url={img03}
              text={
                " Providers of liquidity are susceptible to transient losses, especially in erratic markets. When the value of assets placed fluctuates from the time of deposit, this can happen and result in losses as opposed to just retaining the assets."
              }
              span={""}
              title={"Risk of "}
              end={"Impermanent Loss"}
            />
          </li>
          <li className="overview__item img">
            <Block
              cssClass={"block-bg"}
              url={img04}
              text={
                "While DeFi offers innovative ways to earn, such as yield farming, these opportunities often come with high risk and require substantial capital. The attractive returns advertised by many protocols can fluctuate widely, leading to unpredictability in earnings."
              }
              span={"Earning"}
              title={" and Yield Farming"}
            />
          </li>
          <li className="overview__item img">
            <Block
              cssClass={"block-bg"}
              url={img05}
              text={
                "The DeFi sector has been plagued by security breaches, including smart contract vulnerabilities and exploits. These incidents have led to significant financial losses for users and have eroded trust in the ecosystem."
              }
              span={""}
              title={"Security"}
              end={"Concerns"}
            />
          </li>
          <li className="overview__item img">
            <Block
              cssClass={"block-bg"}
              url={img06}
              text={
                "Transactions on popular blockchains like Ethereum can become prohibitively expensive during peak usage times, due to high gas fees. This not only affects the profitability of participating in DeFi protocols but also limits transactions to those who can afford the fees, thereby reducing inclusivity."
              }
              span={"Scalability"}
              title={" and Gas Fees"}
            />
          </li>
          <li className="overview__item img">
            <Block
              cssClass={"block-bg"}
              url={img07}
              text={
                " The DeFi space operates in a largely unregulated environment, leading to issues related to consumer protection. Without clear regulatory frameworks, users are exposed to a higher risk of fraud and scams."
              }
              span={""}
              title={"Lack of Regulation and "}
              end={"Consumer Protection"}
            />
          </li>
        </ul>
      </div>
      <Block
        textWhite
        text={
          "Despite its potential, the DeFi market has a lot of obstacles to overcome in the areas of earning methods, liquidity pools, accessibility, and other areas. DeFi must overcome these challenges in order to realize its full potential and open up to a larger audience. The next wave of growth in the DeFi sector may be propelled by innovations that improve security, streamline interactions, and clarify regulations."
        }
      />
    </section>
  );
};

export default Overview;
