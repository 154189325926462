import React, { useState } from "react";

import "./Form.scss";
import Input from "../input/Input";

const Form = ({closeModal}) => {
  const [discord, setDiscord] = useState("");
  const [email, setEmail] = useState("");
  const [wallet, setWallet] = useState("");
  const [message, setMessage] = useState("");
  const [btnText, setBtnText] = useState("join waitlist");
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("discord", discord);
    formData.append("email", email);
    formData.append("wallet", wallet);

    fetch(
      "https://script.google.com/macros/s/AKfycbwMdWmNI7412BxcrLInaLaDU0cPfLB9EVzhTV-BMLlmq53G--ykVtYNTao1pGN_QcQN/exec",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((response) => response.text())
      .then((data) => {
        console.log(data);

        setBtnText("Thank you for joining");
        setIsModalOpen(false); // Закриваємо модальне вікно
        
        setDiscord("");
        setEmail("");
        setWallet("");
        
        setTimeout(() => {
          closeModal()
          setBtnText("join waitlist");
        }, 3000);
      })
      .catch((error) => {
        console.error("Помилка:", error);
        setMessage("error");
        setTimeout(() => {
          setMessage("");
        }, 3000);
      });
  };

  const onClickJoin = () => {
    window.BMDataLayer = window.BMDataLayer || [];
    window.BMDataLayer.push({
      conversionID: "6658742a1fd5ec55a7c53e74",
      eventId: "TrueRegistr",
      event: "conversion",
      eventValue: "{ConversionValueUSD}",
    });
  };

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <h3 className="form__title title">
          Join <span>Waitlist</span>
        </h3>

        <Input
          name="discord"
          type="text"
          cssClass={"form__input"}
          label={"Discord"}
          value={discord}
          onChange={(e) => setDiscord(e.target.value)}
          required
        />
        <Input
          name="email"
          type="email"
          cssClass={"form__input"}
          label={"Email"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Input
          name="wallet"
          type="text"
          cssClass={"form__input"}
          label={"Wallet"}
          value={wallet}
          onChange={(e) => setWallet(e.target.value)}
          required
        />
        <button onClick={onClickJoin} type="submit" className="form__button button">
          {btnText}
        </button>
      </form>
    </>
  );
};

export default Form;
