import React from "react";

import "./Roadmap.scss";

const Roadmap = (props) => {
  return (
    <section id="Roadmap" className="roadmap">
      <h2 className="roadmap__title title">
        <span>Roadmap</span>
      </h2>
      <ul className="roadmap__list">
        <li className="roadmap__item roadmap-item">
          <h6 className="roadmap-item__title title-mini">
            <span>Q2</span> 2024
          </h6>
          <p className="roadmap-item__text">
            <span>EVM Testnet + CHR Testnet;</span> start of points program
          </p>
          <p className="roadmap-item__text">
            <span>Q3 Audit;</span> EVM + CHR Mainnet
          </p>
        </li>
        <li className="roadmap__item roadmap-item">
          <h6 className="roadmap-item__title title-mini">
            <span>Q3</span> 2024
          </h6>
          <p className="roadmap-item__text">
            <span>Adding</span> one more EVM chain + DEX{" "}
          </p>
          <p className="roadmap-item__text">
            <span>Integration</span> with 3rd party DeFi dapp
          </p>
          <p className="roadmap-item__text">
            <span>Adding</span> one more EVM chain + DEX
          </p>
        </li>

        <li className="roadmap__item roadmap-item">
          <h6 className="roadmap-item__title title-mini">
            <span>Q4</span> 2024
          </h6>
          <p className="roadmap-item__text">
            <span>Adding</span> one more EVM chain + DEX
          </p>
          <p className="roadmap-item__text">
            <span>Adding</span> lending as a strategy for managers
          </p>
          <p className="roadmap-item__text">
            <span> Adding</span> more dexes and chains
          </p>
        </li>
      </ul>
    </section>
  );
};

export default Roadmap;
