import React from "react";
import logo from "../../img/logo.png";

import "../../style.scss";
import MenuList from "../header/MenuList";

import behance from "../../img/behance.png";
import Social from "./Social";
const customStyle = {
  display: "flex",
  flexDirection: "row",
};
const Footer = ({app,onClick}) => {
   
  
  return (
    <footer className="footer">
      <div className="footer__wraper">
        <div className="footer__body body-footer">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          {app && (
            <>
              <MenuList
              
                footer
                customStyle={customStyle}
                onClick={onClick}
              />
            </>
          )}
          <Social />
        </div>
        {app && (
          <>
            <div className="footer__policy policy-footer">
              <span>© GuarFinance. All rights reserved.</span>
              <span>Privacy Policy</span>
              <span>Terms & Conditions</span>
            </div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.behance.net/winebaee/projects"
              className="footer__desing-author"
            >
              Designed by Polina Onatska
              <img src={behance} alt="behance" />
            </a>
          </>
        )}
      </div>
    </footer>
  );
};

export default Footer;
