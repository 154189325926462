import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../style.scss";
import "swiper/css";
import { Mousewheel } from "swiper/modules";
import img1 from "../../img/01.png";
import img2 from "../../img/02.png";
import img3 from "../../img/03.png";
import img4 from "../../img/04.png";

const sliderItems = [
  {
    title: "Portfolio Tracking",
    text: "Use the 'units system' to track how your investments grow thanks to a fixed interest rate",
    id: 1,
    url: img1,
  },
  {
    title: "Flexible in Deposits and Withdrawals",
    text: "We offer the flexibility to deposit and withdraw your funds at any time",
    id: 2,
    url: img2,
  },
  {
    title: "Diversified Liquidity Pools",
    text: "Investments are spread across various liquidity pools on multiple DEXes, reducing the risk and exposure to volatility in any single asset or market",
    id: 3,
    url: img3,
  },
  {
    title: "Multi-Chain Accessibility",
    text: "Guar Finance system is based on the EVM-agnostic + Rell (Chromia) and Ethereum. Arbitrum, Polygon, Optimism, Avalanche, Manta, Base, and Cronos will be integrated soon",
    id: 4,
    url: img4,
  },
];
const Features = (props) => {
  
  return (
    <section id="Our Features" className="features">
      <div className="features__container">
        <h2 className="features__title title">
          Key <span>Features</span>
        </h2>
      </div>{" "}
      <Swiper
        style={{ overflow: "visible" }}
        spaceBetween={30}
        freeMode={true}
       
        loop={true}
        mousewheel={true}
        modules={[Mousewheel]}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          1285: {
            slidesPerView: 3,
          },
          800: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1.3,
          },
        }}
      >
        {sliderItems.map((item) => {
          return (
            <SwiperSlide className="slide " key={item.id}>
              <div className="slide__header header-slide">
                <div className="header-slide__img">
                  <img src={item.url} alt={item.title} />
                </div>
                <h5 className="header-slide__title title-mini">{item.title}</h5>
              </div>
              <p className="slide__text text">{item.text}</p>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Features;
