import React, { useState,useRef } from "react";
import logo from "../../img/logo.png";
import "../../style.scss";
import MenuList from "./MenuList";
import useMediaQuery from "@mui/material/useMediaQuery";
import Social from "../footer/Social";
const Header = ({app,onClick}) => {

  
  const isMobile = useMediaQuery("(max-width: 750px)");
  const [onActive, setOnActive] = useState(true);
  const toggleActive = () => {
    setOnActive(onActive=>!onActive);
     document.body.style.overflow = !onActive ? "auto" : "hidden"; 
  };
  // const logInPozicion = (
  //   <div className="header__buttons buttons-header">
  //     <button className="buttons-header__btn-transparent btn-transparent">
  //       Log in
  //     </button>
  //     <button className="buttons-header__btn button">Sign Up</button>
  //   </div>
  // );
  const onClickMenu=()=>{
setOnActive(true)
console.log(onActive)
if(isMobile){
  document.body.style.overflow = !onActive ? "auto" : "hidden";
}

  }
  return (
    <header className="header">
      <div className="header__wraper">
        <button onClick={()=>onClick(true)} className="logo">
          <img src={logo} alt="logo" />
        </button>

        {app && <>
        <nav className={onActive ? "header__nav" : "header__nav active"}>
          <MenuList onActive={onClickMenu} onClick={onClick}/>
          {/* {isMobile && logInPozicion} */}
        </nav>
        {/* {!isMobile && logInPozicion} */}
        <button
          onClick={toggleActive}
          className={onActive ? "burger" : " burger burger__active"}
        >
          <span></span>
        </button>
        </> }
        {!app && <Social/>}
        
      </div>
    </header>
  );
};

export default Header;
