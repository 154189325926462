import "../../style.scss";
import { useState, useEffect, useRef } from "react";

const ProgresBar = (props) => {
  const [isInViewWan, setIsInViewWan] = useState(false);
  const [isInViewTwo, setIsInViewTwo] = useState(false);
  const [isInViewThree, setIsInViewThree] = useState(false);

  const wanRef = useRef(null);
  const twoRef = useRef(null);
  const threeRef = useRef(null);

  const checkInView = (ref, setState) => {
    const rect = ref.current.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    if (rect.top <= windowHeight / 2 && rect.bottom >= windowHeight / 2) {
      setState(true);
    }
  };

  const handleScroll = () => {
    checkInView(wanRef, setIsInViewWan);
    checkInView(twoRef, setIsInViewTwo);
    checkInView(threeRef, setIsInViewThree);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll(); 

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="progres-bar">
      <span
        ref={wanRef}
        className={isInViewWan ? "progres-bar__num active" : "progres-bar__num"}
      >
        1<span className={isInViewWan ? "wan active" : "wan"}></span>
      </span>
      <span
        ref={twoRef}
        className={isInViewTwo ? "progres-bar__num active" : "progres-bar__num"}
      >
        2<span className={isInViewTwo ? "two active" : "two"}></span>
      </span>
      <span
        ref={threeRef}
        className={
          isInViewThree ? "progres-bar__num active" : "progres-bar__num"
        }
      >
        3<span className={isInViewThree ? "three active" : "three"}></span>
      </span>
    </div>
  );
};

export default ProgresBar;
