import React from "react";

import "./Roadmap.scss";
import H2Title from "../H2Title";
import Block from "../block/Block";

const Roadmap = (props) => {
  return (
    <section id=" Roadmap" className="roadmap">
      <H2Title span={"4."} title={"Roadmap"} />
      <div className="roadmap__item roadmap-item block-bg">
        <h6 className="roadmap-item__title title-mini">
          <span>Q2</span> 2024
        </h6>
        <Block
          start={"Integration with Additional DEXs:"}
          text={
            " Integrate with more decentralized exchanges on currently supported networks at the start of the year. In order to increase user yield potential and expand liquidity alternatives, this first phase concentrates on well-known DEXs."
          }
        />
        <Block
          start={"Network Assessment and Selection:"}
          text={
            " Make in-depth evaluations of different blockchain networks to determine which ones best align with Guar Finance's growth strategy and user requirements. Networks should be ranked according to their user base, security, and scalability."
          }
        />
      </div>
      <div className="roadmap__item roadmap-item block-bg">
        <h6 className="roadmap-item__title title-mini">
          <span>Q3</span> 2024
        </h6>
        <Block
          start={"Launch on New Networks:"}
          text={
            " Launch Guar Finance on more blockchain networks, concentrating on the ones that were deemed high-priority in Q1. Based on their increasing popularity and technological capabilities, Solana, Polkadot, and Ethereum Layer 2 solutions (such Optimism and Arbitrum) are the first networks to be taken into consideration."
          }
        />
        <Block
          start={"Cross-Chain Functionality:"}
          text={
            " Create and implement technologies that improve cross-chain interactions, facilitating asset transfers between supported networks and enabling users to take advantage of cross-chain yield potential."
          }
        />
      </div>
      <div className="roadmap__item roadmap-item block-bg">
        <h6 className="roadmap-item__title title-mini">
          <span>Q4</span> 2024
        </h6>
        <Block
          start={"Introduction of New Income Strategies:"}
          text={
            " Once the platform is well established on several networks and DEXs, present creative revenue models. This might involve improved liquidity mining software, algorithmic trading methods, and more advanced yield farming approaches."
          }
        />
        <Block
          start={"Community-Driven Development:"}
          text={
            " Interact with the Guar Finance community to get opinions and recommendations for new functions, revenue streams, and network growth. If one isn't currently in place, put in place a governance structure that lets token owners cast votes on important proposals."
          }
        />
      </div>
    </section>
  );
};

export default Roadmap;
