import React from 'react'
import { useEffect } from 'react'
import { createPortal } from 'react-dom'
import './Whitepeper.scss'
import NavList from './navList/NavList'
import Introduction from '../whitepaper/introduction/Introduction'
import Overview from './overview/Overview'
import Conclusion from './conclusion/Conclusion'
import Links from './links/Links'
import Roadmap from './roadmap/Roadmap'
import Finanse from './finanse/Finanse'
import Header from '../header/Header'
import Footer from '../footer/Footer'
const Whitepeper = ({onClick})=>{
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return createPortal(
    <main className='whitepaper'>
      <div className="whitepaper__container">
        <Header onClick={onClick}/>
        <NavList/>
<Introduction/>
<Overview/>
<Finanse/>
<Roadmap/>
<Conclusion/>
<Links/>
<Footer/>
      </div>

    </main>,document.getElementById("whatepaper")
  )
}

export default Whitepeper

