import React from 'react'



const H2Title = ({title,span})=>{
  return (
    <h2 style={{ textAlign: "start" }} className="title">
      <span>{span}</span>
      {title}
    </h2>
  );
}

export default H2Title