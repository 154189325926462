import React from 'react'

import './Input.scss'

const Input = ({label,cssClass,...props})=>{
  return (
<div className={cssClass}>
  <label>{label}</label>
  <input type="email" {...props} />
</div>
  )
}

export default Input