import React from 'react'



const ItemWork = (props)=>{
  const itemWorkInfo = [
    {
      title: "Initial Deposit",
      text: "Deposit stablecoins or major cryptocurrencies like BTC and ETH into our secure GLPY DeFi system",
      id: 1,
    },
    {
      title: "Allocation",
      text: "The deposited funds are allocated across liquidity pools on different decentralized exchanges (DEXes)",
      id: 2,
    },
    {
      title: "Rewards Generation",
      text: "Earn a fixed percentage yield, such as 8% per year which is generated from the commissions that liquidity providers earn on various decentralized exchanges (DEXes)",
      id: 3,
    },
    {
      title: "Unit System Investment",
      text: "Investments are represented as units, which are purchased at a fixed initial price (e.g., 1.0 USDT/unit). These units ensure straightforward tracking of investment growth",
      id: 4,
    },
    {
      title: "Regular Rebalancing",
      text: "The distribution of investments across various liquidity pools is not static. Guar Finance regularly rebalances the portfolio to adapt to market changes, optimize returns, and minimize risks",
      id: 5,
    },
    {
      title: "Withdrawal Anytime",
      text: "Sell units back to the system, at their current value, ensuring you reap the benefits of your investment",
      id: 6,
    },
  ];
  return (
    <ul className='work__list'>
      {itemWorkInfo.map(item=>{
        return (
          <li className="work__item " key={item.id}>
            <h6 className='work__title title-mini'>{item.title}</h6>
            <p className="work__text text">{item.text}</p>
          </li>
        );
      })}
    </ul>
  )
}

export default ItemWork