import React from 'react'

import './Block.scss'

const Block = ({cssClass,span,title,text,guar,textWhite,url,start,end})=>{
  return (
    <div className={`block ${cssClass}`}>
      <h6 className="title-mini">
        {url && <img src={url} alt={"icon"} />}
        <span> {span} </span>{ title} <span>{end}</span>
      </h6>
      <p className={textWhite ? "text-white" : "text-whitepaper"}>
        {start&&<span>{start}</span> }
        {text} {guar&& <span>{guar}</span>}
      </p>
    </div>
  );
}

export default Block